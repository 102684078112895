import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import ElasticSearchModel from 'src/model/elasticsearch/ElasticSearchModel'
import VehicleCard from 'src/components/Card/VehicleCard.vue'

export default {
  name: 'VeiculosCategoria',
  components: { VueSlickCarousel, VehicleCard },
  props: {
    marcaId: {
      type: Number,
      default: 0
    }
    // featured: {
    //   type: Array,
    //   default: () => {
    //     return [
    //       {
    //         img: 'app:home_1',
    //         titulo: '10 dias de Test Drive',
    //         descricao: 'Confiamos tanto em nossos carros, que te damos 10 dias para você ter certeza da compra. Caso não tenha, pode devolver.'
    //       },
    //       {
    //         img: 'app:home_3',
    //         titulo: 'Entregamos em casa!',
    //         descricao: 'Nossos clientes não saem de casa. Em primeiro lugar o conforto. Por isso, levamos o carro até sua casa. Praticidade e transparência. Assim é fácil!'
    //       },
    //       {
    //         img: 'app:home_2',
    //         titulo: '1 ano de garantia!',
    //         descricao: '100 dias de garantia Total e 1 ano para motor e câmbio. Por isso, nossos carros são os mais confiáveis do mercado. Assim é fácil!'
    //       },
    //       {
    //         img: 'app:home_4',
    //         titulo: 'Melhores financiamentos',
    //         descricao: 'Não trabalhamos com intermediários, temos integrações diretas com os bancos. Por isso, as melhoras taxas estão aqui.'
    //       }
    //     ]
    //   }
    // }
  },
  data () {
    return {
      anunciosCarroceria: [],
      carrosselCards: {
        lazyLoad: 'ondemand',
        dots: true,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        centerMode: false,
        responsive: [
          {
            breakpoint: 2400,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              initialSlide: 0
            }
          },
          {
            breakpoint: 1450,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              initialSlide: 0
            }
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              initialSlide: 0
            }
          },
          {
            breakpoint: 1020,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 0
            }
          },
          {
            breakpoint: 915,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 0
            }
          },
          {
            breakpoint: 599,
            settings: {
              arrows: false,
              slidesToShow: 1.15,
              slidesToScroll: 1
            }
          }
        ]
      }
    }
  },
  mounted () {
    this.getAnuncios()
  },
  // computed: {
  //   anunciosList () {
  //     return this.anuncios
  //   }
  // },
  methods: {
    reInit () {
      // if (this.$refs.slick) {
      //   // this.$refs.slick.destroy()
      //   this.$nextTick(() => {
      //     this.$refs.slick.create()
      //     this.$refs.slick.goTo(0, true)
      //   })
      // }
    },
    getAnuncios () {
      let modelElastic = new ElasticSearchModel()
      modelElastic.buscar([
        { tipo: 'multiplo', termo: 'carroceria_objeto.carroceria_nome', label: 'Carroceria', valor: this.$store.getters['data/getData'].carroceria_objeto.carroceria_nome },
        { tipo: 'multiplo', termo: 'situacao_id', label: 'Situação', valor: 1 },
        { tipo: 'multiplo', termo: 'situacao_id', label: 'Situação', valor: 6 }
      ], null, 1, 4).then((retorno) => {
        this.anunciosCarroceria = retorno.hits.hits.map((item) => item._source)
        this.carregandoCarroceria = false
      }).catch((e) => console.error('e', e))
    }
  }
}
